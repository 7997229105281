.modal {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
}

.modal__inner {
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.modal__header {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1.25rem;
    line-height: 1.6;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
}

.error-text {
    color: #FF002C;
    margin-top: -8px;
    margin-left: 4px;
    font-size: 14px;
}

.i-need-fix-mui-btn > span {
    margin: 0;
}

.i-need-fix-mui-btn.delete > span {
    color: #FF002C;
}